import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import "../styles/index.scss"
import { SEO } from "../components/seo"

const Privacy = props => {
  return (
    <Layout pageTitle="Privacy Policy">
      <div className="mainContent">
        <h3>Who we are</h3>
        Our website address is: http://www.media864.com

        <h3>Cookies</h3>
        We don't use any
        
        <h3>Contact / booking forms</h3>

        If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.

        Your contact information
        Additional information
        How we protect your data
        What data breach procedures we have in place
        What third parties we receive data from
        What automated decision making and/or profiling we do with user data
        Industry regulatory disclosure requirements

      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="Privacy" />
)

export default Privacy;